import React, { useState } from 'react';
import {
  Admin,
  Resource,
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  Options,
  AuthProvider,
  List,
  Datagrid,
  TextField,
  useGetList,
} from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

const validateUrl = (url: any) => {
  console.log(url);
  const errors: any = {};
  if (url.startsWith('http://')) {
    errors.url = 'URL must start with https://';
  }
  return errors;
};

const validateFormUrl = (value: any) => {
  if (!value.startsWith('https://')) {
    return 'URL must start with https://';
  }
  return undefined; // No error
};

const customHttpClient = async (url: string, options?: Options | undefined) => {
  const response = await fetch(url, options);
  const newHeaders = new Headers(response.headers);
  const body = await response.json();

  if (Array.isArray(body)) {
    const data = body.map(item => ({
      id: item._id,
      ...item,
      _id: undefined,
    }));
    newHeaders.append('X-Total-Count', data.length.toString());
    return {
      status: response.status,
      headers: newHeaders,
      body,
      json: data,
    };
  } else {
    return {
      status: response.status,
      headers: newHeaders,
      body,
      json: { id: body._id, ...body },
    };
  }
};

const dataProvider = jsonServerProvider(
  process.env.REACT_APP_API_URL + '/api',
  customHttpClient,
);

// Override the default `update` method to use PATCH
const customDataProvider = {
  ...dataProvider,
  update: async (resource: any, params: { id: any; data: any }) => {
    const url = `${process.env.REACT_APP_API_URL}/api/${resource}/${params.id}`;
    const options = {
      method: 'PATCH',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(params.data),
    };
    const response = await customHttpClient(url, options);
    return {
      data: response.json, // Add the data property here
      status: response.status,
      headers: response.headers,
    };
  },
};

const ProductCreate = () => {
  const [message, setMessage] = React.useState<string>();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const urlInput = form.elements.namedItem('url') as HTMLInputElement;
    const errors = validateUrl(urlInput.value);
    if (Object.keys(errors).length === 0) {
      const dataToSend = {
        url: urlInput.value.toString(),
      };
      const res = await fetch(process.env.REACT_APP_API_URL + '/api/products', {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (res.ok) {
        setMessage('Product created successfully');
      }
    } else {
      setMessage(errors.url);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {message && <p>{message}</p>}
      <label>
        URL
        <input
          type='text'
          name='url'
          id='url'
          required
          onChange={() => setMessage(undefined)}
        />
      </label>
      <br />
      <button type='submit'>Create</button>
    </form>
  );
};

const HARD_CODED_USERNAME = 'admin';
const HARD_CODED_PASSWORD = 'password123';

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    if (username === HARD_CODED_USERNAME && password === HARD_CODED_PASSWORD) {
      // If credentials match, allow login
      window.location.replace('/');
      return Promise.resolve(); // Resolve the promise to indicate successful login
    } else {
      return Promise.reject(new Error('Invalid credentials')); // Reject if credentials don't match
    }
  },
  logout: () => {
    localStorage.removeItem('auth_token');
    return Promise.resolve();
  },
  checkError: error => {
    if (error.status === 401 || error.status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem('auth_token')
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
};

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (username === HARD_CODED_USERNAME && password === HARD_CODED_PASSWORD) {
      localStorage.setItem('auth_token', 'dummy-token');
      window.location.replace('/');
    } else {
      setError('Invalid credentials');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          Username
          <input
            type='text'
            value={username}
            onChange={e => setUsername(e.target.value)}
            required
          />
        </label>
      </div>
      <div>
        <label>
          Password
          <input
            type='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
        </label>
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button type='submit'>Login</button>
    </form>
  );
};

export const ProductEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput label='Name' source='name' />
      <TextInput label='URL' source='url' validate={validateFormUrl} />
      <TextInput label='Description' source='description' multiline disabled />
      <TextInput label='Image URL' source='image' disabled />
      <TextInput label='Category' source='category' disabled />
      <TextInput label='Requirements' source='requirements' disabled />
      <TextInput label='Time to Complete' source='timeToComplete' disabled />

      <ArrayInput source='price' disabled>
        <SimpleFormIterator disabled>
          <TextInput source='name' label='Option Name' disabled />
          <ArrayInput source='options' label='Options' disabled>
            <SimpleFormIterator disabled>
              <TextInput source='name' label='Option Name' disabled />
              <TextInput source='usd' label='Price (USD)' disabled />
              <TextInput source='eur' label='Price (EUR)' disabled />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>

      <TextInput label='Original Price (USD)' source='fromPriceUsd' disabled />
      <TextInput label='Original Price (EUR)' source='fromPriceEur' disabled />
    </SimpleForm>
  </Edit>
);

const ProductList: React.FC = () => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const { data, isLoading, error } = useGetList('products', {
    pagination: {
      page,
      perPage: 50,
    },
  });

  // Update search state on input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0);
  };

  if (isLoading && !data) return <p>Loading...</p>;
  if (error) return <p>Error loading products</p>;

  const filteredData = !!search
    ? data?.filter((product: any) =>
        product.name?.toLowerCase().includes(search?.toLowerCase()),
      )
    : data;

  const perPage = 50;
  const total = filteredData?.length || 0;
  const totalPages = Math.ceil(total / perPage);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <input
        placeholder='Search products'
        value={search}
        onChange={handleSearchChange}
        style={{
          padding: '8px',
          width: '320px',
          marginTop: 20,
        }}
      />
      <List resource='products' pagination={false}>
        <Datagrid
          data={filteredData?.slice(page * perPage, (page + 1) * perPage) || []}
          rowClick='edit'
        >
          <TextField source='id' />
          <TextField source='name' />
          <TextField source='url' />
          <TextField source='fromPriceUsd' />
          <TextField source='fromPriceEur' />
        </Datagrid>
      </List>
      {totalPages > 1 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            maxWidth: '100%',
          }}
        >
          {[...Array(totalPages)].map((_, i) => (
            <button
              style={{
                padding: '8px',
                paddingLeft: '12px',
                paddingRight: '12px',
                borderColor: 'lightblue',
                color: 'blueviolet',
                margin: '8px',
                backgroundColor: i === page ? 'lightblue' : 'white',
              }}
              key={i}
              onClick={() => handlePageChange(i)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      )}
    </>
  );
};

const App: React.FC = () => (
  <Admin
    dataProvider={customDataProvider}
    authProvider={authProvider}
    loginPage={LoginPage} // Use the custom login page
  >
    <Resource
      name='products'
      list={ProductList}
      create={ProductCreate}
      edit={ProductEdit}
    />
  </Admin>
);

export default App;
